
.device-item {

    border : solid;
    border-color: white;
    border-radius:15px;
    color :white;

    margin :10px;
    padding :10px;
    font-size: 10px;
}

.selected {

    background-color: red;
    
}